import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'

import SvgFeatureTick from '../images/icons/FeatureTick.svg'
import { ANIMATION, BREAKPOINTS } from '../styles/cssVariables'

const Feature = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 58px;
  transition: background-color ${ANIMATION.speedSlow} ease;
  @media (max-width: ${BREAKPOINTS.med}px) {
    padding-top: 26px;
    padding-bottom: 26px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding-top: 18px;
    padding-bottom: 18px;
  }
`

const CardHeading = styled.h3`
  position: relative;
  margin-bottom: 10px;
`

const SvgFeatureTickPositioned = styled(SvgFeatureTick)`
  position: absolute;
  top: 0;
  left: -43px;

  opacity: 1;
  transform: translateX(-50%);
  transition: opacity ${ANIMATION.speedSlow} ease;

  circle {
    fill: var(--color-skobeloff);
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    left: -35px;
    top: -4px;
  }
`

/**
 * Renders a card with a bullet in tick SVG form, heading text, and smaller body text.
 * Examples on homepage Feature List section, Join the Communty page, etc.
 */
const FeatureCard = (props) => {
  return (
    <Feature>
      <CardHeading>
        <SvgFeatureTickPositioned $accentColor={props.accentColor} />
        {props.heading}
      </CardHeading>
      {props.body && <p>{props.body}</p>}
    </Feature>
  )
}

FeatureCard.propTypes = {
  /** The colour of the bullets */
  accentColor: propTypes.string.isRequired,
  /** Bullet heading text */
  heading: propTypes.string,
  /** Bullet body text */
  body: propTypes.string,
}

export default FeatureCard
