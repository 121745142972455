import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import get from 'lodash/get'
import styled from 'styled-components'
import Layout from '../components/layout/layout'
import Container from '../components/layout/container'
import FeatureList from '../components/featureList'
import EmojiGrid from '../components/emojiGrid'
import { BREAKPOINTS, LAYOUT, ANIMATION } from '../styles/cssVariables'
import SEO from '../components/layout/seo'
import Hero from '../components/hero'
import HeroSecondaryApp from '../components/ui-kit/heroSecondaryApp'
import Button from '../components/ui-kit/button'
import GatsbyLink from 'gatsby-link'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import IconLinkArrowSvg from '../images/icons/link-arrow.svg'
import { isAbsoluteUrl } from '../utils/utils'

const FeatureListWrapper = styled.section`
  margin-bottom: 200px;
  @media (max-width: ${BREAKPOINTS.med}px) {
    margin-bottom: 175px;
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-bottom: 150px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-bottom: 100px;
  }
  > div div div div div picture img {
    border-radius: 4px;
  }
`

const FeatureListTitle = styled.h2`
  margin-bottom: 42px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    width: 100%;
  }


`

const DonationsDescription = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  margin-bottom: 50px;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    width: 100%;
  }
  > p {
    text-align: center;

  }
  > p b {
    font-weight: 700 !important;
  }
`

const ContributorsSection = styled.section`
  margin-bottom: 200px;
  @media (max-width: ${BREAKPOINTS.med}px) {
    margin-bottom: 175px;
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-bottom: 150px;
  }
  // @media (max-width: ${BREAKPOINTS.sml}px) {
  //   margin-bottom: 100px;
  // }
`

const KeyBenefitsTitle = styled.h2`
  margin-bottom: 42px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    margin-bottom: 27px;
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-bottom: 20px;
    width: 100%;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-bottom: 15px;
    
  }
`

const KeyBenefitsSection = styled.section`
  margin-bottom: 200px;
  @media (max-width: ${BREAKPOINTS.med}px) {
    margin-bottom: 175px;
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-bottom: 150px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-bottom: 100px;
  }
`
const ContributorsBody = styled.p`
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  margin-bottom: 50px;

  text-align: center;
  
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    width: 100%;
  }
`

const DonationsSubtitle = styled.h3`
  width: 50%;
  margin-bottom: 10px;
  margin-left: 55px;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
`

const ContributorsHeading = styled.h2`
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 50px;
`
const DonationLogo = styled(GatsbyImage)`
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
`

const ContributorImage = styled(GatsbyImage)`
  width: 500px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    width: 90%;
  }
`


const ContributorButtonGatsbyLink = styled(Link)`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid var(--color-skobeloff);
  background-color: var(--color-skobeloff);
  color:  var(--color-white) !important;
  cursor: pointer;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 55px;

  margin-left: auto;
  margin-right: auto;
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: transparent;
    color:  var(--color-skobeloff) !important;
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
 
  
`
const FeatureButton = styled.a`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid var(--color-skobeloff);
  background-color: var(--color-skobeloff);
  color: var(--color-white) !important;
  cursor: pointer;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 15px;
  
  margin-left: auto;
  margin-right: auto;
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: transparent;
    color:  var(--color-skobeloff) !important;
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  
`

const FeatureButtonGatsbyLink = styled(Link)`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid var(--color-skobeloff);
  background-color: var(--color-skobeloff);
  color:  var(--color-white) !important;
  cursor: pointer;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 15px;

  margin-left: auto;
  margin-right: auto;
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: transparent;
    color:  var(--color-skobeloff) !important;
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  
`


const Arrow = styled(IconLinkArrowSvg)`
  margin-left: 0.4em;
`

class AppPage extends React.Component {
  render() {
    const heroHeading = get(this, 'props.data.contentfulPageApp.hero.heading')
    const heroSubheading = get(
      this,
      'props.data.contentfulPageApp.hero.subheading'
    )
    const heroCtaText = get(this, 'props.data.contentfulPageApp.hero.ctaText')
    const heroCtaDestination = get(
      this,
      'props.data.contentfulPageApp.hero.ctaDestination'
    )
    const heroSecondaryHeading = get(
      this,
      'props.data.contentfulPageApp.heroSecondaryHeading'
    )
    const heroSecondaryBody = get(
      this,
      'props.data.contentfulPageApp.heroSecondaryBody.heroSecondaryBody'
    )
    const heroSecondaryCTAText = get(
        this,
        'props.data.contentfulPageApp.secondaryHeroCtaText'
      )
      const heroSecondaryCTADestination = get(
        this,
        'props.data.contentfulPageApp.secondaryHeroCtaDesintation'
      )
    // const openGraphImage = get(this, 'props.data.contentfulPageApp.openGraphImage')

    const featureListLayout = get(
      this,
      'props.data.contentfulPageApp.downloadShopifyApp.layout'
    )
    const featureListHeading = get(
      this,
      'props.data.contentfulPageApp.downloadShopifyApp.title'
    )
    const featureListImage = get(
      this,
      'props.data.contentfulPageApp.downloadShopifyApp.image'
    )
    const featureListCards = get(
      this,
      'props.data.contentfulPageApp.downloadShopifyApp.featureCards'
    )
    const featureListCtaText = get(
      this,
      'props.data.contentfulPageApp.downloadShopifyApp.ctaText'
    )
    const featureListCtaDestination = get(
      this,
      'props.data.contentfulPageApp.downloadShopifyApp.ctaDestination'
    )
    const secondFeatureListLayout = get(
      this,
      'props.data.contentfulPageApp.howItWorks.layout'
    )
    const secondFeatureListHeading = get(
      this,
      'props.data.contentfulPageApp.howItWorks.title'
    )
    const secondFeatureListImage = get(
      this,
      'props.data.contentfulPageApp.howItWorks.image'
    )
    const secondFeatureListCards = get(
      this,
      'props.data.contentfulPageApp.howItWorks.featureCards'
    )
    const secondFeatureListCtaText = get(
      this,
      'props.data.contentfulPageApp.howItWorks.ctaText'
    )
    const secondFeatureListCtaDestination = get(
      this,
      'props.data.contentfulPageApp.howItWorks.ctaDestination'
    )
    // const dontaionsListLayout = get(
    //     this,
    //     'props.data.contentfulPageApp.whereDonationsGo.featureList.layout'
    //   )
    //   const donationsListHeading = get(
    //     this,
    //     'props.data.contentfulPageApp.whereDonationsGo.featureList.title'
    //   )
    //   const donationsListImage = get(
    //     this,
    //     'props.data.contentfulPageApp.whereDonationsGo.featureList.image'
    //   )
    //   const donationsListCards = get(
    //     this,
    //     'props.data.contentfulPageApp.whereDonationsGo.featureList.featureCards'
    //   )
    //   const donationsListCtaText = get(
    //     this,
    //     'props.data.contentfulPageApp.whereDonationsGo.featureList.ctaText'
    //   )
    //   const donationsListCtaDestination = get(
    //     this,
    //     'props.data.contentfulPageApp.whereDonationsGo.featureList.ctaDestination'
    //   )
 
    //   const donationsLogo = get(
    //     this,
    //     'props.data.contentfulPageApp.whereDonationsGo.logo'
    //   )
    //   const donationsDescription = get(
    //     this,
    //     'props.data.contentfulPageApp.whereDonationsGo.description'
    //   )
    //   const donationsSubtitle= get(
    //     this,
    //     'props.data.contentfulPageApp.whereDonationsGo.subtitle'
    //   )
      const contributorsDescription = get(
        this,
        'props.data.contentfulPageApp.contributorsDescription.contributorsDescription'
      )     
      const contributorsCTADestination = get(
        this,
        'props.data.contentfulPageApp.contributorsCtaDestination'
      )
      const contributorsCTAText = get(
        this,
        'props.data.contentfulPageApp.contributorsCtaTitle'
      )
      const contributorsTitle = get(
        this,
        'props.data.contentfulPageApp.contributorsTitle'
      )
      const contributorsImage = get(
        this,
        'props.data.contentfulPageApp.contributorsImage'
      )
      const contributorsImageTitle = get(
        this,
        'props.data.contentfulPageApp.contributorsImage.title'
      )
      const keyBenefitsTitle = get(
        this,
        'props.data.contentfulPageApp.keyBenefits.title'
      )
      const keyBenefitsCards = get(
        this,
        'props.data.contentfulPageApp.keyBenefits.emojiGridCard'
      )

      const keyBenefitsCTAText = get(
        this,
        'props.data.contentfulPageApp.keyBenefits.ctaText'
      )

      const keyBenefitsCTADestination = get(
        this,
        'props.data.contentfulPageApp.keyBenefits.ctaDestination'
      )
  

    const seoTitle = get(this, 'props.data.contentfulPageApp.seoTitle')
    const seoDescription = get(this, 'props.data.contentfulPageApp.seoDescription')
    // const donationsDescriptionBody = JSON.parse(donationsDescription.raw)
   
  return (
      <Layout location={this.props.location}>
        <SEO
          pageTitle={seoTitle}
          seoDescription={seoDescription}
          // ogImage={openGraphImage.fluid.srcWebp}
        />

      <Hero
        heading={heroHeading}
        subheading={heroSubheading}
        ctaText={heroCtaText}
        ctaDestination={heroCtaDestination}
      />

        <HeroSecondaryApp title={heroSecondaryHeading} body={heroSecondaryBody} />
        {heroSecondaryCTAText && (
            <Button 
                className="secondary-hero-button" 
                type="solid" 
                href={heroSecondaryCTADestination} 
                target="_blank"
                rel="nofollow">
                {heroSecondaryCTAText}
            </Button>
        )}
        {/* Are you struggling with these common problems when growing your mindful business? */}

        <FeatureListWrapper>
          <Container width={12}>
            <FeatureListTitle>
              {featureListHeading}
            </FeatureListTitle>

              <FeatureList
              ctaText={featureListCtaText}
              ctaDestination={featureListCtaDestination}
              featureCardArr={featureListCards.map((card) => [
                card.heading,
                card.body.body,
              ])}
              layout={featureListLayout}
              image={featureListImage}
            />
  
            
          </Container>
        </FeatureListWrapper>
        {/* Lessons included in the programme: */}

        <FeatureListWrapper>
          <Container width={12}>
          <FeatureListTitle>
             {secondFeatureListHeading}
            </FeatureListTitle>
            <FeatureList
              ctaText={secondFeatureListCtaText}
              ctaDestination={secondFeatureListCtaDestination}
              featureCardArr={secondFeatureListCards.map((card) => [
                card.heading,
                card.body.body,
              ])}
              layout={secondFeatureListLayout}
              image={secondFeatureListImage}
            />
          </Container>
        </FeatureListWrapper>

        {/* Key Benefits */}

              <KeyBenefitsSection>
              <Container width={12}>
                  <EmojiGrid 
                    heading={keyBenefitsTitle}
                    EmojiGridArr={keyBenefitsCards}
                  />
      {keyBenefitsCTAText && (
      <>
        {isAbsoluteUrl(keyBenefitsCTADestination) ? (
          <FeatureButton
            href={keyBenefitsCTADestination}
            target="_blank"
            rel="nofollow"
          >
            {keyBenefitsCTAText}
            <Arrow />
          </FeatureButton>
        ) : (
          <FeatureButtonGatsbyLink to={keyBenefitsCTADestination}>
            {keyBenefitsCTAText}
            <Arrow />
          </FeatureButtonGatsbyLink>
        )}   
    </>
    )}
                </Container>
              </KeyBenefitsSection>
              {/* Where do donations go? */}
       
       {/* {donationsListHeading && (

        <FeatureListWrapper>
          <Container width={12}>
          <FeatureListTitle>
                {donationsListHeading}
            </FeatureListTitle>
            {donationsDescriptionBody && (
            <DonationsDescription dangerouslySetInnerHTML={{
            __html: documentToHtmlString(donationsDescriptionBody)
            }} />
            )}
        {donationsLogo && (
            <DonationLogo image={donationsLogo.gatsbyImageData} alt="British Red Cross logo" />
            )}
            <DonationsSubtitle>{donationsSubtitle}</DonationsSubtitle>
            <FeatureList
              ctaText={donationsListCtaText}
              ctaDestination={donationsListCtaDestination}
              featureCardArr={donationsListCards.map((card) => [
                card.heading,
                card.body.body,
              ])}
              layout={dontaionsListLayout}
              image={donationsListImage}
            />
          </Container>
        </FeatureListWrapper>
        )} */}
        <ContributorsSection>
        <Container width={12}>
            <ContributorsHeading>{contributorsTitle}</ContributorsHeading>
            <ContributorsBody>{contributorsDescription}</ContributorsBody>
            <ContributorImage image={contributorsImage.gatsbyImageData} alt={contributorsImageTitle} />
            <ContributorButtonGatsbyLink to={contributorsCTADestination}>
                {contributorsCTAText}
                <Arrow />
            </ContributorButtonGatsbyLink>
          </Container>
        </ContributorsSection>


      </Layout>
    )
  }
}

export default AppPage

export const appQuery = graphql`
  query appQuery {
    contentfulPageApp {
        seoTitle
        seoDescription
        title
        contributorsCtaDestination
        contributorsCtaTitle
        contributorsDescription {
          contributorsDescription
        }
        heroSecondaryHeading
        secondaryHeroCtaText
        secondaryHeroCtaDestination
        contributorsTitle
        contributorsImage {
          title
          gatsbyImageData(layout: FULL_WIDTH)
        }
        keyBenefits {
          ctaText
          ctaDestination
          emojiGridCard {
            body {
              body
            }
            emoji
            title
          }
          title
        }
        hero {
          heading
          ctaText
          ctaDestination
          subheading
        }
        downloadShopifyApp {
            ctaText
            ctaDestination
            layout
            title
            image {
                title
                  gatsbyImageData(layout: FULL_WIDTH)
              
              }
            featureCards {
              heading
              body {
                body
              }
            }
          }
        howItWorks {
          ctaText
          ctaDestination
          layout
          title
          image {
            title
              gatsbyImageData(layout: FULL_WIDTH)
          
          }
          featureCards {
            heading
            body {
              body
            }
          }
        }
       
    
      }
    
  }
`
