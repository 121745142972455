import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import slugify from 'slugify'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import IconLinkArrowSvg from '../images/icons/link-arrow.svg'
import FeatureCard from './featureCard'
import { BREAKPOINTS, ANIMATION, LAYOUT } from '../styles/cssVariables'
import { isAbsoluteUrl } from '../utils/utils'

const FeatureListFlex = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.layout === 'Image on Right' ? 'row-reverse' : 'row'};

  @media (max-width: ${BREAKPOINTS.med}px) {
    flex-direction: column;
  }
`
const FeatureListColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const ImagePanel = styled.div`
  ${(props) => (props.offsetImage ? 'flex: 11;' : 'flex: 1;')}

  // image should be offset, on the left
  ${(props) =>
    props.offsetImage &&
    props.layout === 'Image on Left' &&
    `
    align-self: center;
    margin-right: ${LAYOUT.columnWidth}%;
    @media (max-width: ${BREAKPOINTS.med}px) {
       margin-right: 0;
    }
    
  `}

  // image should be offset, on the right
  ${(props) =>
    props.offsetImage &&
    props.layout === 'Image on Right' &&
    `
    margin-left: ${LAYOUT.columnWidth}%;
    align-self: center;
    @media (max-width: ${BREAKPOINTS.med}px) {
       margin-left: 0;
    }
    `}

  // image should not be offset, on the left
  ${(props) =>
    !props.offsetImage &&
    props.layout === 'Image on Left' &&
    `
    align-self: center;
    margin-right: 55px;
    @media (max-width: ${BREAKPOINTS.med}px) {
       margin-right: 0;
    }
  `}

  // image should not be offset, on the right
  ${(props) =>
    !props.offsetImage &&
    props.layout === 'Image on Right' &&
    `
    align-self: center;
    margin-left: 55px;
    @media (max-width: ${BREAKPOINTS.med}px) {
       margin-left: 0;
    }
 
  `}

  @media (max-width: ${BREAKPOINTS.med}px) {
    width: 100%;
    margin-right: auto;
    margin-bottom: 40px;
    margin-left: auto;
    transform: translateX(0);
  }
`

const CardPanel = styled.div`
  ${(props) => (props.offsetImage ? 'flex: 9;' : 'flex: 1;')}

  ${(props) =>
    !props.offsetImage
      ? props.layout === 'Image on Left'
        ? `margin-right: 55px;`
        : `margin-left: 55px;`
      : null}
      @media (max-width: ${BREAKPOINTS.med}px) {
        margin-left: auto;
        margin-right: auto;
      }
`
const CardPanelNoImage = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  @media (max-width: 1060px) {
    width: 100%;
    
  }
`

const FeatureListTitle = styled.h2`
  margin-bottom: 42px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`
const FeatureButton = styled.a`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid var(--color-skobeloff);
  background-color: var(--color-skobeloff);
  color: var(--color-white) !important;
  cursor: pointer;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 15px;
  
  margin-left: auto;
  margin-right: auto;
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: transparent;
    color:  var(--color-skobeloff) !important;
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  
`

const FeatureButtonGatsbyLink = styled(Link)`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid var(--color-skobeloff);
  background-color: var(--color-skobeloff);
  color:  var(--color-white) !important;
  cursor: pointer;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 15px;

  margin-left: auto;
  margin-right: auto;
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: transparent;
    color:  var(--color-skobeloff) !important;
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  
`
const Arrow = styled(IconLinkArrowSvg)`
  margin-left: 0.4em;
`


/**
 * A list of bullet points with accompanying featured image.
 * Optional CTA.
 */
const FeatureList = (props) => {
  return (
    <>
{props.image ? (
  <>
  <FeatureListFlex className={props.className} layout={props.layout}>
  <ImagePanel layout={props.layout} offsetImage={props.offsetImage}>
    <GatsbyImage
      alt={props.image.title}
      image={props.image.gatsbyImageData}
    />
  </ImagePanel>

  <CardPanel offsetImage={props.offsetImage}>
  {props.heading && <FeatureListTitle>{props.heading}</FeatureListTitle>}

    {props.featureCardArr.map((feature, i) => (
      <FeatureCard
        accentColor={props.accentColor}
        heading={feature[0]}
        key={slugify(feature[0])}
        body={feature[1]}
      />
    ))}

    {props.ctaText && (
      <>
        {isAbsoluteUrl(props.ctaDestination) ? (
          <FeatureButton
            href={props.ctaDestination}
            target="_blank"
            rel="nofollow"
          >
            {props.ctaText}
            <Arrow />
          </FeatureButton>
        ) : (
          <FeatureButtonGatsbyLink to={props.ctaDestination}>
            {props.ctaText}
            <Arrow />
          </FeatureButtonGatsbyLink>
        )}   
    </>
    )}
  </CardPanel>
</FeatureListFlex>
</>
  ) : (
    <>
    <FeatureListColumn>
   
    <CardPanelNoImage>
    {props.heading && <FeatureListTitle>{props.heading}</FeatureListTitle>}
  
      {props.featureCardArr.map((feature, i) => (
        <FeatureCard
          accentColor={props.accentColor}
          heading={feature[0]}
          key={slugify(feature[0])}
          body={feature[1]}
        />
      ))}
  
      {props.ctaText && (
        <>
          {isAbsoluteUrl(props.ctaDestination) ? (
            <FeatureButton
              href={props.ctaDestination}
              target="_blank"
              rel="nofollow"
            >
              {props.ctaText}
              <Arrow />
            </FeatureButton>
          ) : (
            <FeatureButtonGatsbyLink to={props.ctaDestination}>
              {props.ctaText}
              <Arrow />
            </FeatureButtonGatsbyLink>
          )}   
      </>
      )}
    </CardPanelNoImage>
  </FeatureListColumn>
  </>
  )}
</>
  )
}

FeatureList.propTypes = {
  /** The colour of the bullets */
  accentColor: PropTypes.string.isRequired,
  /** Button properties */
  callToAction: PropTypes.elementType,
  /** Content to populate bullets */
  featureCardArr: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
    .isRequired,
  /** Feature list heading text */
  heading: PropTypes.string,
  /** Dictates which side the image is shown, on desktop devices */
  // layout: PropTypes.oneOf(['Image on Left', 'Image on Right']).isRequired,
  // image: PropTypes.shape({ gatsbyImageData: PropTypes.object }).isRequired,
  // offsetImage: PropTypes.bool.isRequired,
}

FeatureList.defaultProps = {
  accentColor: 'var(--color-blue)',
  // layout: 'Image on Left',
  // offsetImage: true,
}

export default FeatureList
