import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import IconLinkArrowSvg from '../images/icons/link-arrow.svg'
import { BREAKPOINTS, ANIMATION, LAYOUT } from '../styles/cssVariables'
import { isAbsoluteUrl } from '../utils/utils'

const GridFlex = styled.div`
  display: flex;
  flex-direction: column;
  
`



const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 33px;
  row-gap: 66px;
  @media (max-width: 1200px) {
    column-gap: 20px;
  }
  @media (max-width: 1079px) {
    grid-template-columns: 1fr 1fr;
    margin-left: auto;
    margin-right: auto;
   
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    grid-template-columns: 1fr;
 
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    column-gap: 15px;

  }
  @media (max-width: 320px) {
    column-gap: 10px;

  }
`

const ContentCard = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 15px;

`


const ContentCardText = styled.div`
  display: flex;
  flex-direction: column;
`

const CardHeading = styled.h3`
  margin-bottom: 10px;
`
const CardBody = styled.p`

`



const Emojis = styled.p`
    display: inline-block;

    // align-self: center;
`

const GridTitle = styled.h2`
  margin-bottom: 42px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`
const FeatureButton = styled.a`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid var(--color-skobeloff);
  background-color: var(--color-skobeloff);
  color: var(--color-white) !important;
  cursor: pointer;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 15px;
  
  margin-left: auto;
  margin-right: auto;
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: transparent;
    color:  var(--color-skobeloff) !important;
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  
`

const FeatureButtonGatsbyLink = styled(Link)`
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid var(--color-skobeloff);
  background-color: var(--color-skobeloff);
  color:  var(--color-white) !important;
  cursor: pointer;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  margin-top: 15px;

  margin-left: auto;
  margin-right: auto;
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: transparent;
    color:  var(--color-skobeloff) !important;
  }

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  
`
const Arrow = styled(IconLinkArrowSvg)`
  margin-left: 0.4em;
`


/**
 * A list of bullet points with accompanying featured image.
 * Optional CTA.
 */
const EmojiGrid = (props) => {
  return (

  <GridFlex>

  
  <GridTitle>{props.heading}</GridTitle>
    <ContentGrid>   
    {props.EmojiGridArr.map((benefit, i) => (
        <ContentCard key={i}>
            <Emojis>{benefit.emoji}</Emojis>

            <ContentCardText>
                <CardHeading>{benefit.title}</CardHeading>
                <CardBody>{benefit.body.body}</CardBody>
            </ContentCardText>
        </ContentCard>
      
    
    ))}

    {/* {props.ctaText && (
      <>
        {isAbsoluteUrl(props.ctaDestination) ? (
          <FeatureButton
            href={props.ctaDestination}
            target="_blank"
            rel="nofollow"
          >
            {props.ctaText}
            <Arrow />
          </FeatureButton>
        ) : (
          <FeatureButtonGatsbyLink to={props.ctaDestination}>
            {props.ctaText}
            <Arrow />
          </FeatureButtonGatsbyLink>
        )}   
    </>
    )} */}
  </ContentGrid>
</GridFlex>
  )
}



export default EmojiGrid
